// console.log('Ready javascript');

// Mobile > all touchstar DOM perfect Iphone
document.addEventListener("touchstart", function(){}, true);


// When the user scrolls the page, execute myFunction
window.onscroll = function() { myFunction(); };

// Get the header
var body = document.getElementById("body");

// Get the offset position of the navbar
var sticky = body.offsetTop;

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
function myFunction() {
  //if (window.pageYOffset > sticky && window.innerWidth > 1120) {
    if (window.pageYOffset > sticky) {
    // document.getElementById("main").style.paddingTop = header.clientHeight + ".px";
    body.classList.add("is-active-scroll");
  } else {
    // document.getElementById("main").style.paddingTop = 0;
    body.classList.remove("is-active-scroll");
  }
}


$("a").on('click', function(event) {
  if (this.hash !== "") {
    var hash = this.hash;
    var headerHeight = document.getElementsByTagName("header");
    if (headerHeight[0].classList != "fixed"){
      var topPage = $(hash).offset().top;
    } else {
      var topPage = $(hash).offset().top;
    }
    $('#body').removeClass('is-active-menu');
    $('#js-header__menu-mobile , .header__layer').removeClass('is-active');
    $('html, body').animate({
      scrollTop: topPage
    }, 700, function(){
      window.location.hash = hash;
      var archos = window.location.hash.substr(1);
    });
  } 
});



$('#footer .js-eventClick-open-child').click(function(){
  $(this).toggleClass('is-open');
  $(this).siblings().removeClass('is-open');
});

$('#header .js-eventClick-open-child').click(function(){
  if ($('#body').hasClass('is-active-menu')){
    $(this).toggleClass('is-open');
    $(this).siblings().removeClass('is-open');
  }
});

$(document).keyup(function(e) {
  if ($('#body').hasClass('is-active-menu')) {
    if (e.keyCode === 27) { $('#js-header__menu-mobile').click();}
  }
});

//jQuery( document ).ready(function( $ ) {
$('#js-body__header__menu-mobile').click(function(){
  $('#js-header__menu-mobile').click();
});


  $slick = $('#js-c-banner-home__slider');
  $slick.slick({
    arrows: false,
    dots:true,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    adaptiveHeight: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    fade: true,
    cssEase: 'linear',
    speed: 800,
    adaptiveHeight: false,
   lazyLoad: 'ondemand',
  });
  
  $('#js-header__menu-mobile').click(function(){
    $('#body').toggleClass('is-active-menu');
    $(this).toggleClass('is-active');
    $('.header__layer').toggleClass('is-active');
  });

  $('.js-carousel').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    dots:true,
    responsive: [
    {
      breakpoint: 768,
      settings: {
        dots: false,
        //centerMode: true,
        // centerPadding: '80px',
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
    ]
  });
  $('.js-marquee').slick({
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 0,
    centerMode: true,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: true,
    initialSlide: 1,
    arrows: false,
    buttons: false,
    
  });
//});





